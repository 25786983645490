<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-no-repeat bg-fixed bg-cover bg-[url('../assets/background_mobile.jpg')] sm:bg-[url('../assets/fairy_background.png')] ">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden rounded-lg bg-secondary-300 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                            <form class="bg-secondary-300 shadow-sm sm:rounded-xl md:col-span-2" @submit.prevent="">
                                <div>
                                    <div class="mt-3 text-center sm:mt-5">
                                        <DialogTitle as="h3" class="text-xl font-semibold leading-6 text-primary-500">Herzlich Willkommen beim Märchengenerator</DialogTitle>
                                        <div class="mt-2">
                                            <p class="text-sm text-gray-500">Bitte gib mindestens deinen Namen und dein Alter an</p>

                                            <div class="px-4 py-6 sm:p-8">
                                                <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                                    <div class="sm:col-span-3">
                                                        <label for="name" class="block text-sm font-medium leading-6 text-primary text-start">Dein Name</label>
                                                        <div class="mt-2">
                                                            <input v-model="form.name" type="text" name="name" id="name" autocomplete="name" class="block bg-secondary-300 w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" :disabled="disabled" />
                                                        </div>
                                                    </div>


                                                    <div class="sm:col-span-4">
                                                        <label for="age" class="block text-sm font-medium leading-6 text-primary text-start">Dein Alter</label>
                                                        <div class="mt-2">
                                                            <input v-model="form.age" id="age" name="age" type="number" autocomplete="age" class="block bg-secondary-300 w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" :disabled="disabled" />
                                                        </div>
                                                    </div>

                                                    <div class="sm:col-span-4">
                                                        <label for="country" class="block text-sm font-medium leading-6 text-primary text-start">Junge o. Mädchen</label>
                                                        <div class="mt-2">
                                                            <select v-model="form.sex" id="country" name="country" autocomplete="country-name" class="block bg-secondary-300 w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6" :disabled="disabled">
                                                                <option>Junge</option>
                                                                <option>Mädchen</option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div class="sm:col-span-2 sm:col-start-1">
                                                        <label for="city" class="block text-sm font-medium leading-6 text-primary text-start">Haarfarbe</label>
                                                        <div class="mt-2">

                                                            <select v-model="form.haircolor" id="country" name="country" autocomplete="country-name" class="bg-secondary-300 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 
                                                            sm:max-w-xs sm:text-sm sm:leading-6" :disabled="disabled">
                                                                <option>braun</option>
                                                                <option>blond</option>
                                                                <option>schwarz</option>
                                                                <option>rot</option>
                                                                <option>weiß</option>
                                                                <option>grau</option>
                                                                <option>blau</option>
                                                                <option>grün</option>
                                                                <option>lila</option>
                                                                <option>rosa</option>
                                                                <option>orange</option>
                                                                <option>gelb</option>
                                                                <option>gold</option>
                                                                <option>silber</option>
                                                                <option>kupfer</option>
                                                                <option>bernstein</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="sm:col-span-2">
                                                        <label for="region" class="block text-sm font-medium leading-6 text-primary text-start">Deine Frisur</label>
                                                        <div class="mt-2">
                                                            <select v-model="form.hair" id="country" name="country" autocomplete="country-name" class="block w-full bg-secondary-300 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6" :disabled="disabled">
                                                                <option>Kurz</option>
                                                                <option>Mittelkurz</option>
                                                                <option>Locken</option>
                                                                <option>glattes Haar</option>
                                                                <option>Wuschelfrisur</option>
                                                                <option>Bob</option>
                                                                <option>Pixie-Cut</option>
                                                                <option>Langer Pferdeschwanz</option>
                                                                <option>Hoher Pferdeschwanz</option>
                                                                <option>Dutt</option>
                                                                <option>Chignon</option>
                                                                <option>Sidecut</option>
                                                                <option>Undercut</option>
                                                                <option>Wellen</option>
                                                                <option>Zopf</option>
                                                                <option>Fischgrätenzopf</option>
                                                                <option>Top Knot</option>
                                                                <option>Hochsteckfrisur</option>
                                                                <option>Blunt Cut</option>
                                                                <option>Long Bob</option>
                                                                <option>Messy Bun</option>
                                                                <option>French Twist</option>
                                                                <option>Quiff</option>
                                                                <option>Spiky Hair</option>
                                                                <option>Slicked Back</option>
                                                                <option>Braid Crown</option>
                                                                <option>Mohawk</option>
                                                                <option>Afro</option>
                                                                <option>Curly Bob</option>
                                                                <option>Curly Afro</option>
                                                                <option>Cornrows</option>
                                                                <option>Bantu Knots</option>
                                                                <option>Frisch geschnittene Kurzhaarfrisur</option>
                                                                <option>Lockige Frisur mit seitlichem Scheitel</option>
                                                                <option>Geflochtene Zöpfe mit Perlen und Glasperlen</option>
                                                                <option>Gebunden mit einem Haarband</option>
                                                                <option>Schalfrisur</option>
                                                                <option>Natürliche Afro-Textur</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-5 sm:mt-6">
                                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:bg-primary-50" @click="open = false" :disabled="starter
                                    ">{{ button_msg }}</button>
                                </div>
                            </form>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { ref, reactive, defineEmits, watch, computed } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
const form = reactive({
    name: '',
    age: '',
    sex: 'Mädchen',
    hair: '',
    animal: 'Löwe',
    haircolor: ''
});
const emit = defineEmits(['update']);

const disabled = ref(false);
const starter = ref(true);
const open = ref(true)

watch(form, res => {
    if (form.name.length > 0 && form.age > 0 && form.hair.length > 0 && form.haircolor.length > 0) {
        starter.value = false;
    }
    emit('update', res)
})


const button_msg = computed(() => {
    if (starter.value === true) {
        return 'Bitte gib vorher noch alle Daten an'
    } else {
        return 'Los geht`s';
    }

});
</script>
