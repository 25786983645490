
<template>

    <div class="h-full w-full  bg-[url('../assets/background_mobile.jpg')] sm:bg-[url('../assets/fairy_background.png')]  bg-cover bg-fixed">
    <Popover as="header" class=" pb-24" v-slot="{ open }">
        <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div class="relative flex items-center justify-center py-5 lg:justify-between">
                    <!-- Logo -->
                    <div class="absolute left-0 flex-shrink-0 lg:static">
                        <a href="#">
                        <span class="sr-only">Märchen Generator</span>
                        <img class="h-8 w-auto" src="../assets/logo.png" alt="Märchen Generator" />
                    </a>
                </div>

                <!-- Right section on desktop -->
                <div class="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
                   
                    </div>

                <!-- Menu button -->
                <div class="absolute right-0 flex-shrink-0 lg:hidden">
                        <!-- Mobile menu button -->
                        <!-- <PopoverButton class="inline-flex items-center justify-center rounded-md bg-transparent p-2 text-primary-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                        <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                    </PopoverButton> -->
                </div>
            </div>
            <div class="hidden border-t border-white border-opacity-20 py-5 lg:block">
                <div class="grid grid-cols-3 items-center gap-8">
                    <div class="col-span-2">
                            <!-- <nav class="flex space-x-4">
                                                            <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'text-white' : 'text-primary-100', 'rounded-md bg-white bg-opacity-0 px-3 py-2 text-sm font-medium hover:bg-opacity-10']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
                                                        </nav> -->
                        </div>
                        <div>
                            <!-- <div class="mx-auto w-full max-w-md">
                                                            <label for="mobile-search" class="sr-only">Search</label>
                                                            <div class="relative text-white focus-within:text-gray-600">
                                                                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                                    <MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true" />
                                                                </div>
                                                                <input id="mobile-search" class="block w-full rounded-md border-0 bg-white/20 py-1.5 pl-10 pr-3 text-white placeholder:text-white focus:bg-white focus:text-gray-900 focus:ring-0 focus:placeholder:text-gray-500 sm:text-sm sm:leading-6" placeholder="Search" type="search" name="search" />
                                                            </div>
                                                        </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <TransitionRoot as="template" :show="open">
                <div class="lg:hidden">
                    <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-150 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                        <PopoverOverlay class="fixed inset-0 z-20 bg-black bg-opacity-25" />
                    </TransitionChild>

                    <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-150 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                        <PopoverPanel focus class="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition">
                            <div class="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div class="pb-2 pt-3">
                                    <div class="flex items-center justify-between px-4">
                                        <div>
                                            <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=primary&shade=600" alt="Märchen Generator" />
                                        </div>
                                        <div class="-mr-2">
                                            <PopoverButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                                <span class="sr-only">Close menu</span>
                                                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                            </PopoverButton>
                                        </div>
                                    </div>
                                    <div class="mt-3 space-y-1 px-2">
                                        <a href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Home</a>
                                        <a href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Profile</a>
                                        <a href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Resources</a>
                                        <a href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Company Directory</a>
                                        <a href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">Openings</a>
                                    </div>
                                </div>
                                <div class="pb-2 pt-4">
                                    <div class="flex items-center px-5">
                                        <div class="flex-shrink-0">
                                            <img class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" />
                                        </div>
                                        <div class="ml-3 min-w-0 flex-1">
                                            <div class="truncate text-base font-medium text-gray-800">{{ user.name }}</div>
                                            <div class="truncate text-sm font-medium text-gray-500">{{ user.email }}</div>
                                        </div>
                                        <button type="button" class="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                                            <span class="sr-only">View notifications</span>
                                            <BellIcon class="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div class="mt-3 space-y-1 px-2">
                                        <a v-for="item in userNavigation" :key="item.name" :href="item.href" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">{{ item.name }}</a>
                                    </div>
                                </div>
                            </div>
                        </PopoverPanel>
                    </TransitionChild>
                </div>
            </TransitionRoot>
        </Popover>
        <main class="-mt-24 pb-8">
            <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <h1 class="sr-only"></h1>
                <!-- Main 3 column grid -->
                <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
                    <!-- Left col -->
                    <div class="grid grid-cols-1 gap-4">
                        <section aria-labelledby="section-2-title">
                            <h2 class="sr-only" id="section-2-title">Section title</h2>
                            <div class="overflow-hidden bg-secondary-300 rounded-lg shadow">
                                <div class="p-6">
                                    <fairy-form :formdata="form" @data="emitFormData" :disabled="disabled" @submit="getTale" @deleteHistory="deleteHistory" />
                                </div>
                            </div>
                        </section>
                    </div>

                    <!-- Right col -->
                    <div class="grid grid-cols-1 gap-4 lg:col-span-2">
                        <section aria-labelledby="section-1-title">

                            <div class="overflow-hidden rounded-lg ">
                                <div class="">
                                    <!-- Your content -->

                                    <div v-if="isLoading" class="flex justify-center items-center m-5">
                                        <div role="status">
                                            <svg aria-hidden="true" class="w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill" />
                                            </svg>
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <!-- <div v-if="got_story">
                                        <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ story_title }}</h2>
                                        <div>
                                            {{ story_content }}
                                        </div>
                                    </div> -->
                                    <div v-if="show_history">

                                        <div v-for="historyItem in history" :key="historyItem.title" class="bg-secondary-300 overflow-hidden rounded-lg p-6 mb-4">
                                            <h2 class="mt-2 text-2xl md:text-3xl font-bold tracking-tight text-primary-600 sm:text-4xl">{{ historyItem.title }}</h2>
                                           <img v-if="historyItem.image" :src="historyItem.image" width="300" height="300" class=" rounded-lg float-left mr-4" />
    <span class="md:text-base text-sm">{{ historyItem.text }}</span>
                                            <div class="mt-1 text-xs">Geschrieben am {{ formatDate(historyItem.date) }}</div>
                                        </div>

                                        <div v-if="history.length == 0" class="bg-secondary-300 overflow-hidden rounded-lg p-6 mb-4">
                                            Bisher noch keine Märchen vorhanden.<br>
                                            <button type="submit" class="my-2 rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600" @click="getTale" :disabled="disabled">Neues Märchen</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
        <footer>
            <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div class="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-left"><span class="block sm:inline">&copy; Tobeworks 2023, Version {{ appdata.version }}</span> <span class="block sm:inline"></span></div>
            </div>
        </footer>
    </div>

    <FairyPopup @update="getStartForm" v-if="start_screen"></FairyPopup>
    <Overlay v-if="isLoading" />
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import axios from 'axios';
import {
    // Menu,
    // MenuButton,
    // MenuItem,
    // MenuItems,
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import { BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
//import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
//import bg from '../assets/fairy_background.png';
import FairyPopup from '../components/Fairy-Popup.vue'
import FairyForm from '../components/Fairy-Form.vue'
import Overlay from '../components/Fairy-Loadingoverlay.vue'
import appdata from '../../package.json'

const user = {
    name: 'Tobias Lorsbach',
    email: 'tom@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
]

const got_story = ref(false);
const isLoading = ref(false);
const story_content = ref(null);
const story_title = ref(null);
const story_image = ref();
const start_screen = ref(true);
const show_history = ref(true);
const history = ref([]);
const disabled = ref(false);
const history_data_name = 'maerchengenerator_history-v2';
const history_data_form = `${history_data_name}-formdata`;
const form = reactive({
    name: '',
    age: '',
    sex: 'Mädchen',
    hair: 'Kurz',
    animal: 'Löwe',
    color: 'black',
    haircolor: 'blond',
    hobby: 'Apps programmieren',
    settings: {
        charakter: 'Ritter',
        set: 'Schloss im Wald',
        plot: 'Abenteuer',
        plot_description: ''
    }
});



onMounted(() => {
    const savedForm = JSON.parse(localStorage.getItem(history_data_form));
    if (savedForm != null) {
        start_screen.value = false;
    }
    if (savedForm) {
        for (const [key, value] of Object.entries(savedForm)) {
            form[key] = value;
        }
    }
    showHistory();
});

const getTale = () => {
    generateFairyTale();

}

const getStartForm = (res) => {
    for (const [key, value] of Object.entries(res)) {
        form[key] = value;
    }
}

function generateFairyTale() {
    got_story.value = false;
    isLoading.value = true;
    disabled.value = true;
    const payload = {
        name: form.name,
        age: form.age,
        sex: form.sex,
        hobby: form.hobby,
        hair: `${form.haircolor} ${form.hair}`,
        settings: {
            charakter: form.settings.charakter,
            set: form.settings.set,
            plot: form.settings.plot,
            plot_description: form.settings.plot_description
        }
    };

    let url;
    if (process.env.NODE_ENV === 'development') {
        url = 'http://localhost:5000';
    } else {
        url = 'https://twapi1-nr2qe7lb3q-oa.a.run.app';
    }

    axios.post(`${url}/generate-fairy-tale`, payload)
        .then(response => {

            saveFormToLocalStorage();

            const json_data = response.data;
            
            if (json_data.text != undefined && json_data.text.length > 1) {
                story_content.value = json_data.text;
                story_title.value = json_data.title;
                story_image.value = json_data.image;
                isLoading.value = false;
                disabled.value = false;

                saveToLocalStorage(json_data);
                showHistory();
                saveFormToLocalStorage();
            } else {
                alert('Der Märchenonkel konnte das Märchen leider nicht ganz zu Ende schreiben, bitte versuche es erneut');
                location.reload();
            }
        })
        .catch(error => {
            console.log(error);
        });
}

function saveToLocalStorage(obj) {
    const new_id = Math.random().toString(36).substr(2, 9);
    let existingData = JSON.parse(localStorage.getItem(history_data_name) || '[]');
    const new_obj = { "id": new_id, "date": new Date().toISOString(), ...obj };
    existingData.push(new_obj);
    localStorage.setItem(history_data_name, JSON.stringify(existingData));
}

function showHistory() {
    show_history.value = true;
    const historyData = JSON.parse(localStorage.getItem(history_data_name));
    if (historyData != null) {
        historyData.sort((a, b) => new Date(b.date) - new Date(a.date));
        history.value = historyData;
    }
}

function deleteHistory() {
    localStorage.removeItem(history_data_name);
    localStorage.removeItem(history_data_form);
    showHistory();
    location.reload();
}

const emitFormData = (res) =>{
    for (const [key, value] of Object.entries(res)) {
        form[key] = value;
    }
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    };
    return date.toLocaleTimeString('de-DE', options);
}

function saveFormToLocalStorage() {
    localStorage.setItem(history_data_form, JSON.stringify(form));
}

</script>