<template>
    <form class="shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" @submit.prevent="">
        <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                <div class="sm:col-span-3">
                    <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Dein Vorname</label>
                    <div class="mt-2">
                        <input v-model="form.name" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 placeholder:text-primary-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 bg-secondary-300 disabled:bg-gray-200" :disabled="disabled" />
                    </div>
                </div>


                <div class="sm:col-span-4">
                    <label for="age" class="block text-sm font-medium leading-6 text-gray-900">Dein Alter</label>
                    <div class="mt-2">
                        <input v-model="form.age" id="age" name="age" type="number" autocomplete="age" class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 bg-secondary-300 disabled:bg-gray-200" :disabled="disabled" />
                    </div>
                </div>

                <div class="sm:col-span-4">
                    <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Junge o. Mädchen</label>
                    <div class="mt-2">
                        <select v-model="form.sex" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6 bg-secondary-300 disabled:bg-gray-200" :disabled="disabled">
                            <option>Junge</option>
                            <option>Mädchen</option>
                        </select>
                    </div>
                </div>


                <div class="col-span-full">
                    <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Dein Lieblingscharakter</label>
                    <div class="mt-2">
                        <select v-model="form.settings.charakter" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6 bg-secondary-300 disabled:bg-gray-200" :disabled="disabled">
                            <option>Prinzessin</option>
                            <option>Zauberer</option>
                            <option>Elfe</option>
                            <option>Ork</option>
                            <option>Prinz</option>
                            <option>König</option>
                            <option>Ritter</option>
                            <option>Wikinger</option>
                            <option>Hexe</option>
                            <option>Paladin</option>
                            <option>Feenprinz</option>
                            <option>Drache</option>
                            <option>Feenwesen</option>
                            <option>Goblin</option>
                            <option>Märchenprinz</option>
                            <option>Fee</option>
                            <option>Zwerg</option>
                            <option>Die kleine Meerjungfrau</option>
                            <option>Das tapfere Schneiderlein</option>
                            <option>Hänsel und Gretel</option>
                            <option>Rumpelstilzchen</option>
                            <option>Schneeweißchen und Rosenrot</option>
                            <option>Aschenputtel</option>
                            <option>Rotkäppchen</option>
                            <option>Der Froschkönig</option>
                            <option>Der gestiefelte Kater</option>
                            <option>Dornröschen</option>
                            <option>Die drei kleinen Schweinchen</option>
                            <option>Der Wolf und die sieben Geißlein</option>
                            <option>Ali Baba und die vierzig Räuber</option>
                            <option>Aladdin</option>
                            <option>Peter Pan</option>
                            <option>Robin Hood</option>
                            <option>Sherlock Holmes</option>
                            <option>Die Schöne und das Biest</option>
                            <option>Die kleine Meerjungfrau</option>
                            <option>Das tapfere Schneiderlein</option>
                            <option>Hänsel und Gretel</option>
                            <option>Rumpelstilzchen</option>
                            <option>Schneeweißchen und Rosenrot</option>
                            <option>Aschenputtel</option>
                            <option>Rotkäppchen</option>
                            <option>Der Froschkönig</option>
                            <option>Der gestiefelte Kater</option>
                            <option>Dornröschen</option>
                            <option>Die drei kleinen Schweinchen</option>
                            <option>Der Wolf und die sieben Geißlein</option>
                            <option>Ali Baba und die vierzig Räuber</option>
                            <option>Aladdin</option>
                            <option>Peter Pan</option>
                            <option>Robin Hood</option>
                            <option>Sherlock Holmes</option>
                            <option>Cinderella</option>
                            <option>Der Zauberlehrling</option>
                            <option>Hans im Glück</option>
                            <option>Die Bremer Stadtmusikanten</option>
                            <option>Die Prinzessin auf der Erbse</option>
                            <option>Der Rattenfänger von Hameln</option>
                            <option>Das Dschungelbuch</option>
                            <option>Pocahontas</option>
                            <option>Die Schneekönigin</option>
                            <option>Die wilden Schwäne</option>
                            <option>Der fliegende Holländer</option>
                            <option>Der goldene Vogel</option>
                            <option>Der Hase und der Igel</option>
                        </select>
                    </div>
                </div>

                <div class="col-span-full">
                    <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Deine Lieblingsumgebung</label>
                    <div class="mt-2">
                        <select v-model="form.settings.set" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6 bg-secondary-300 disabled:bg-gray-200" :disabled="disabled">
                            <option>Schloss im Wald</option>
                            <option>Im Wald</option>
                            <option>In einem Wolkenparadies</option>
                            <option>Schloss im Gebirge</option>
                            <option>Märchenwald</option>
                            <option>Zauberwald</option>
                            <option>Feenland</option>
                            <option>Eisiges Königreich</option>
                            <option>Höhle des Drachen</option>
                            <option>Gefängnis des Riesen</option>
                            <option>Zauberberg</option>
                            <option>Märchenschloss</option>
                            <option>Zauberhöhle</option>
                            <option>Wunderland</option>
                            <option>Geheime Garten</option>
                            <option>Dunkler Wald</option>
                            <option>Zauberwiese</option>
                            <option>Unterwasserwelt</option>
                            <option>Verzauberte Ruine</option>
                            <option>Im Himmel</option>
                            <option>Unterirdischer Palast</option>
                            <option>Zauberhafte Insel</option>
                            <option>Geheimnisvolles Tal</option>
                            <option>Wolkenstadt</option>
                            <option>Wüste des Wunders</option>
                            <option>Unterirdischer See</option>
                            <option>Trollhöhle</option>
                            <option>Schloss am Meer</option>
                            <option>Bergdorf</option>
                            <option>Verzauberte Bibliothek</option>
                            <option>Unterirdischer Fluss</option>
                            <option>Schloss auf der Wolke</option>
                            <option>Fliegende Insel</option>
                            <option>Geisterstadt</option>
                            <option>Drachenhort</option>
                            <option>Palast der Winde</option>
                            <option>Verbotener Garten</option>
                            <option>Magische Taverne</option>
                            <option>Märchenküste</option>
                            <option>Zauberstube</option>
                            <option>Elfenreich</option>
                            <option>Eisgrotte</option>
                            <option>Schloss im Wasserfall</option>
                            <option>Märchenwiese</option>
                            <option>Geheimnisvolle Grotte</option>
                            <option>Zauberhafte Berge</option>
                            <option>Schloss im Nebel</option>
                            <option>Höhle der Wunder</option>
                            <option>Mystischer Wald</option>
                            <option>Unterirdischer Tempel</option>
                            <option>Zauberhafte Oase</option>
                            <option>Wunderhöhle</option>
                            <option>Schloss auf dem Berg</option>
                            <option>Zauberhafte Höhle</option>
                            <option>Verzaubertes Labyrinth</option>
                            <option>Verfluchtes Schloss</option>
                            <option>Unterirdischer Garten</option>
                            <option>Schloss im Eis</option>
                            <option>Drachenhöhle</option>
                            <option>Verzaubertes Meer</option>
                            <option>Zauberhafter Wasserfall</option>
                            <option>Wald des Vergessens</option>
                            <option>Mystischer See</option>
                            <option>Verzauberter Waldsee</option>
                            <option>Unterirdischer Markt</option>
                            <option>Magische Wüste</option>
                            <option>Schloss in den Wolken</option>
                            <option>Höhle des Schreckens</option>
                            <option>Unterirdischer Wald</option>
                            <option>Schloss im Feuer</option>
                            <option>Verzauberter Fluss</option>
                            <option>Wunderbaum</option>
                            <option>Magischer See</option>
                            <option>Unterirdisches Reich</option>
                            <option>Zauberhaftes Schmuckkästchen</option>
                            <option>Märchenhaftes Schloss</option>
                            <option>Palast der Träume</option>
                            <option>Verzaubertes Bergdorf</option>
                            <option>Schloss im Regenbogen</option>
                            <option>Unterirdischer See der Sterne</option>
                            <option>Zauberhaftes Dorf</option>
                            <option>Wundersamer Garten</option>
                            <option>Märchenhafter Hafen</option>
                            <option>Verzaubertes Schmuckkästchen</option>
                            <option>Magischer Turm</option>
                        </select>
                    </div>
                </div>

                <div class="sm:col-span-2 sm:col-start-1">
                    <label for="city" class="block text-sm font-medium leading-6 text-gray-900">Haarfarbe</label>
                    <div class="mt-2">

                        <select v-model="form.haircolor" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6 bg-secondary-300 disabled:bg-gray-200" :disabled="disabled">
                            <option>braun</option>
                            <option>blond</option>
                            <option>schwarz</option>
                            <option>rot</option>
                            <option>weiß</option>
                            <option>grau</option>
                            <option>blau</option>
                            <option>grün</option>
                            <option>lila</option>
                            <option>rosa</option>
                            <option>orange</option>
                            <option>gelb</option>
                            <option>gold</option>
                            <option>silber</option>
                            <option>kupfer</option>
                            <option>bernstein</option>
                            <option>platinblond</option>
                            <option>dunkelbraun</option>
                            <option>honigblond</option>
                            <option>rotbraun</option>
                            <option>aubergine</option>
                            <option>türkis</option>
                            <option>lavendel</option>
                            <option>apricot</option>
                            <option>mahagoni</option>
                            <option>pastellpink</option>
                        </select>
                        <!-- <input type="text" name="city" id="city" autocomplete="address-level2" v-model="form.haircolor" class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" /> -->


                    </div>
                </div>

                <div class="sm:col-span-2">
                    <label for="region" class="block text-sm font-medium leading-6 text-gray-900">Deine Frisur</label>
                    <div class="mt-2">
                        <select v-model="form.hair" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6 bg-secondary-300 disabled:bg-gray-200" :disabled="disabled">
                            <option>Kurz</option>
                            <option>Mittelkurz</option>
                            <option>Lang</option>
                            <option>sehr lang</option>
                            <option>halblang</option>
                            <option>Glatze</option>
                            <option>Stoppel</option>
                            <option>Locken</option>
                            <option>glattes Haar</option>
                            <option>Wuschelfrisur</option>
                            <option>Bob</option>
                            <option>Pixie-Cut</option>
                            <option>Langer Pferdeschwanz</option>
                            <option>Hoher Pferdeschwanz</option>
                            <option>Dutt</option>
                            <option>Chignon</option>
                            <option>Sidecut</option>
                            <option>Undercut</option>
                            <option>Wellen</option>
                            <option>Zopf</option>
                            <option>Fischgrätenzopf</option>
                            <option>Top Knot</option>
                            <option>Hochsteckfrisur</option>
                            <option>Blunt Cut</option>
                            <option>Long Bob</option>
                            <option>Messy Bun</option>
                            <option>French Twist</option>
                            <option>Quiff</option>
                            <option>Spiky Hair</option>
                            <option>Slicked Back</option>
                            <option>Braid Crown</option>
                            <option>Mohawk</option>
                            <option>Afro</option>
                            <option>Curly Bob</option>
                            <option>Curly Afro</option>
                            <option>Cornrows</option>
                            <option>Bantu Knots</option>
                            <option>Frisch geschnittene Kurzhaarfrisur</option>
                            <option>Lockige Frisur mit seitlichem Scheitel</option>
                            <option>Geflochtene Zöpfe mit Perlen und Glasperlen</option>
                            <option>Gebunden mit einem Haarband</option>
                            <option>Schalfrisur</option>
                            <option>Natürliche Afro-Textur</option>
                        </select>

                        <!-- <input type="text" name="region" id="region" autocomplete="address-level1" v-model="form.hair" class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" /> -->


                    </div>
                </div>

                <div class="sm:col-span-3">
                        <label for="hobby" class="block text-sm font-medium leading-6 text-gray-900">Dein Hobby</label>
                        <div class="mt-2">
                            <input v-model="form.hobby" type="text" name="hobby" id="hobby" autocomplete="given-name" class="block rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 placeholder:text-primary-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 bg-secondary-300 disabled:bg-gray-200" :disabled="disabled" />
                        </div>
                </div>

                    <div class="col-span-full">
                        <label for="plot" class="block text-sm font-medium leading-6 text-gray-900">Handlung</label>
                        <div class="mt-2">
                            <select v-model="form.settings.plot" id="plot" name="plot" autocomplete="plote" class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6 bg-secondary-300 disabled:bg-gray-200" :disabled="disabled">
                            <option v-for="(element) in handlungen" :key="element.id" :value="element.name">{{ element.name}}</option>
                            </select>
                        </div>
                    </div>

            </div>
        </div>
        <div class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button type="button" class="text-sm font-semibold leading-6 text-gray-900" @click="deleteHistory">History löschen</button>
            <button type="submit" class="rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:bg-gray-200" @click="getTale" :disabled="disabled">Neues Märchen</button>
        </div>
    </form>
</template>
<script setup>
import { reactive, defineEmits, watch, defineProps, ref } from 'vue';

const handlungen = [
    {
        "id": 1,
        "name": "Abenteuer",
        "description": "Eine spannende Reise oder Mission, auf der die Hauptfigur verschiedene Herausforderungen bewältigen muss."
    },
    {
        "id": 2,
        "name": "Rätsel",
        "description": "Die Hauptfigur muss komplexe Rätsel lösen, um ein Ziel zu erreichen oder um eine Situation zu bewältigen."
    },
    {
        "id": 3,
        "name": "Magie",
        "description": "Die Verwendung von magischen Kräften oder Zauberei durch Charaktere oder in der Umgebung."
    },
    {
        "id": 4,
        "name": "Freundschaft",
        "description": "Die Entwicklung von Freundschaften und Zusammenarbeit zwischen Charakteren."
    },
    {
        "id": 5,
        "name": "Liebe",
        "description": "Eine romantische Beziehung oder die Suche nach wahrer Liebe als zentrales Thema."
    },
    {
        "id": 6,
        "name": "Verwandlung",
        "description": "Charaktere oder Objekte, die ihre Gestalt oder ihr Wesen ändern."
    },
    {
        "id": 7,
        "name": "Heldentum",
        "description": "Die Hauptfigur wird zum Helden, indem sie Mut, Weisheit oder andere Tugenden zeigt."
    },
    {
        "id": 8,
        "name": "Wettstreit",
        "description": "Ein Wettkampf oder Kampf, bei dem die Hauptfigur ihre Fähigkeiten unter Beweis stellen muss."
    },
    {
        "id": 9,
        "name": "Schatzsuche",
        "description": "Die Suche nach einem verborgenen oder verlorenen Schatz, der für die Hauptfigur oder andere von großer Bedeutung ist."
    },
    {
        "id": 10,
        "name": "Reise in eine andere Welt",
        "description": "Die Hauptfigur gelangt in eine andere Welt oder Dimension, in der sie auf ungewöhnliche Herausforderungen und Charaktere trifft."
    },
    {
        "id": 11,
        "name": "Selbstfindung",
        "description": "Die Hauptfigur durchläuft eine persönliche Entwicklung und entdeckt ihre eigenen Stärken und Schwächen."
    },
    {
        "id": 12,
        "name": "Erlösung",
        "description": "Ein Charakter, der von einem Fehler oder einer schlechten Tat geplagt ist, sucht nach Erlösung und Vergebung."
    },
    {
        "id": 13,
        "name": "Intrigen und Verrat",
        "description": "Die Hauptfigur wird in politische oder soziale Intrigen verwickelt und muss sich gegen Verrat oder Täuschung behaupten."
    },
    {
        "id": 14,
        "name": "Geheimnisse",
        "description": "Die Aufdeckung verborgener Geheimnisse, die das Schicksal der Hauptfigur oder anderer beeinflussen."
    }, {
        "id": 15,
        "name": "Übernatürliche Wesen",
        "description": "Die Begegnung mit Fabelwesen, Geistern, Monstern oder anderen übernatürlichen Kreaturen."

    }];

const form = reactive({
    name: '',
    age: '',
    sex: 'Mädchen',
    hair: 'Kurz',
    animal: 'Löwe',
    color: 'black',
    hobby:'Apps programmieren',
    haircolor: 'blond',
    settings: {
        charakter: 'Ritter',
        set: 'Schloss im Wald',
        plot: 'Abenteuer',
        plot_description:''
    }
});
const disabled = ref(false);
const props = defineProps(['disabled', 'formdata']);
const emit = defineEmits(['data', 'submit', 'deleteHistory']);




watch(() => props.disabled, (oldVal) => {
    //console.log(oldVal,newVal);
    disabled.value = oldVal;
});

watch(props.formdata, (old, newVal) => {
    for (const [key, value] of Object.entries(newVal)) {
        form[key] = value;
    }
    form.settings.plot_description = handlungen.filter(h => h.name === form.settings.plot).map(h => h.description)[0]
});

watch(form, res => {
    emit('data', res)
});

const getTale = () => {
    emit('submit', true)
};
const deleteHistory = () => {
    emit('deleteHistory', true)
};

</script>